.song-list-container {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 10px;
  height: calc(80vh - 110px); /* Adjust based on the height of other elements */
}

/* Scrollable song list */
.scrollable-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1; /* Allow the list to take the remaining space */
}

/* Song list */
.song-list {
  align-items: center;
  list-style-type: none;
  padding: 10px 20px; /* Add vertical and horizontal padding */
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 90%;
}

/* Song item */
.song-item {
  display: flex;
  justify-content: space-between; /* Space out the details evenly */
  align-items: center; /* Center the content vertically */
  padding: 10px;
  margin: 10px 0; /* Add vertical margin for space between items */
  background-color: #f0f8ff;
  width: 100%; /* Make the item fill the width of its container */
  max-width: 900px; /* Center the song item and prevent it from being too wide */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px; /* Add rounded corners */
}

.song-item.selected {
  background-color: #ffcccc;
}

.song-item.editing {
  background-color: aquamarine;
}

/* Individual song details */
.song-detail {
  flex: 1;
  white-space: nowrap;
  /* Prevent text from wrapping */
}
