.upload-container {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent */
    backdrop-filter: blur(10px); /* Blur effect */
    border-radius: 10px;
    height: calc(80vh - 110px); /* Adjust based on the height of other elements */
  }