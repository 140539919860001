body {
    background-image: url("./components/assets/bg.jpg");
    background-size: cover; /* Cover the entire viewport */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

/* total container */
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

h1 {
    text-align: center;
    width: 100%;
}

/* banner container */
.banner {
    width: 100%;
    max-width: 1100px;
    height: 20vh;
    min-height: 50px;
}

.buttons-container {
    display: flex;


    width: 100%;
    max-width: 1100px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.search-bar {
    flex: 1;
    padding: 5px;
}



/* Pagination buttons */
.pagination {
    display: flex;
    justify-content: center; /* Center the pagination buttons */
    margin-top: 10px;
}