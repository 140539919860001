.controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    /* Prevent controls from shrinking */
}

.toggle-button {
    width: 60px;
    height: 40px;
    background-color: #ccc;
    border: 2px solid #888;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    outline: none;
  }
  
  /* This class is added dynamically when the button is pressed */
  .toggle-button.pressed {
    background-color: #2196F3;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .search-button {
    width: 33%;
    max-width: 180px;
  }